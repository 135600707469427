import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax, ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import '../../scss/sonos-blue-note.scss'
// components
import SEO from '../../components/SEO';
import ParallaxImage from '../../components/parallax/ParallaxImage';
import SectionTrigger from '../../components/SectionTrigger';
import VideoPlayer from '../../components/VideoPlayer';
// utils
import handlePageTransition from '../../lib/handle-page-transition';

function SonosBlueNotePage({ transitionStatus }) {

  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const data = useStaticQuery(graphql`
    query SonosBlueNotePage {
      allCloudinaryMedia(
        filter: { tags: { eq: "sonos-blue-note-case-study" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
    }
  `)
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];

  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });

  const [
    img01,
    img02,
    img03,
    poster04,
    img05,
    img06,
    img07,
    img08,
    poster09,
    img10,
    img11,
    poster12,
    poster13,
    img14,
    img15,
    img16,
  ] = images;

  const vid04 = 'https://player.vimeo.com/external/491022714.sd.mp4?s=c545de876686bd2873c0adf7097de35a66701d6f&profile_id=165&oauth2_token_id=1365998561';
  const vid09 = 'https://player.vimeo.com/external/491022695.sd.mp4?s=1743df45876c69dac3f30e551aefbd036468383f&profile_id=165&oauth2_token_id=1365998561';
  const vid12 = 'https://player.vimeo.com/external/491022648.hd.mp4?s=b98de31b88db702d7ff1ae96cbe68e540724be41&profile_id=174&oauth2_token_id=1365998561';
  const vid13 = 'https://player.vimeo.com/external/491022482.hd.mp4?s=1d866eb14c113c1abe48f3aeb32e3b05039c77f9&profile_id=175&oauth2_token_id=1365998561';

  return (
    <div>
      <SEO title="SONOS x Blue Note | Falkon Content" />
      <div className="sbn container" style={{ overflowX: 'hidden' }}>
        <SectionTrigger
          className="sbn-section sbn-section--1"
          bgColor="#aed1d8"
          textColor="#fff"
        />
        <SectionTrigger
          className="sbn-section sbn-section--2"
          bgColor="#507d9b"
          textColor="#fff"
        />
        <SectionTrigger
          className="sbn-section sbn-section--3"
          bgColor="#2f43a3"
          textColor="#fff"
        />
        <SectionTrigger
          className="sbn-section sbn-section--4"
          bgColor="#d1d7e2"
          textColor="#000"
        />
        <Challenge
          images={[img01, img02, img03, poster04]}
          videos={[vid04]}
        />
        <Solution images={[img05, img06, img07]} />
        <Execution
          images={[img08, poster09, img10, img11, poster12]}
          videos={[vid09, vid12]}
        />
        <Conclusion
          images={[poster13, img14, img15, img16]}
          videos={[vid13]}
        />
      </div>
    </div>
  )
}

function Challenge({ images, videos }) {
  const [img01, img02, img03, poster04] = images;
  const [vid04] = videos;

  return (
    <div className="sbn-challenge">
      <div className="sbn-challenge__header">
        <p className="sbn-challenge__pre-heading">
          Sonos x Bluenote
        </p>
        <h3 className="sbn-challenge__heading">
          Born In Blue
        </h3>
        <p className="sbn-challenge__sub-heading">
          Introducing the Sonos Blue Note Play:1
        </p>
      </div>
      <h3 className="sbn-challenge__content-title">
        Challenge
      </h3>
      <p className="sbn-challenge__content-copy">
        How to unveil the brand's first
        <br />
        limited edition collaboration
        <br />
        with Blue Note: the Sonos
        <br />
        Blue Note PLAY:1.
      </p>
      <div className="sbn-challenge__img sbn-challenge__img--1">
        <img src={img01} alt="title treatment" />
      </div>
      <div className="sbn-challenge__img sbn-challenge__img--2">
        <img src={img02} alt="speaker" />
      </div>
      <Parallax
        className="sbn-challenge__img sbn-challenge__img--3"
        y={[40, -40]}
      >
        <ParallaxImage src={img03} alt="face" />
      </Parallax>
      <VideoPlayer
        className="sbn-challenge__vid sbn-challenge__vid--1"
        poster={poster04}
        src={vid04}
      />
    </div>
  )
}

function Solution({ images }) {
  const [img05, img06, img07] = images;

  return (
    <div className="sbn-solution">
      <div className="sbn-solution__header">
        <h3 className="main-heading sbn-solution__heading">
          Solution
        </h3>
      </div>
      <div className="sbn-solution__paragraph">
        <p className="main-copy">
          Through collaboration,
          <br />
          create an expression of Blue
          <br />
          Note's singular style by
          <br />
          creating a series of short
          <br />
          form content films featuring
          <br />
          some of the world's most
          <br />
          influential musicians, think:
          <br />
          Q-tip, Lupe Fiasco, Robert
          <br />
          Glasper, and Dr. Lonnie-
          <br />
          Smith.
          <br />
          <br />
          Pair the product release with
          <br />
          custom curated content
          <br />
          featuring the best playlists
          <br />
          from the Blue Note catalog.
        </p>
      </div>
      <Parallax
        className="sbn-solution__blockquote"
        y={[80, -40]}
      >
        <blockquote className="blockquote">
          &ldquo;We wanted to create
          <br />
          an expression of Blue
          <br />
          Note’s singular style...&rdquo;
        </blockquote>
        <p className="tagline">
          BLUE NOTE PRESIDENT, DON WAS.
        </p>
      </Parallax>
      <Parallax
        className="sbn-solution__img sbn-solution__img--1"
        y={[40, -40]}
      >
        <ParallaxImage src={img05} alt="title treatment" />
      </Parallax>
      <div className="sbn-solution__img sbn-solution__img--2">
        <img src={img06} alt="speaker" />
      </div>
      <div className="sbn-solution__img sbn-solution__img--3">
        <img src={img07} alt="face" />
      </div>
    </div>
  )
}

function Execution({ images, videos }) {
  const [img08, poster09, img10, img11, poster12] = images;
  const [vid09, vid12] = videos;

  return (
    <div className="sbn-execution">
      <div className="sbn-execution__header">
        <h3 className="main-heading sbn-execution__heading">
          Execution
        </h3>
      </div>
      <div className="sbn-execution__paragraph sbn-execution__paragraph--1">
        <p className="main-copy">
        Using a lean “content”
        <br />
        approach we shot 2-5 min
        <br />
        films with an elevated eye & a
        <br />
        small footprint.
        </p>
      </div>
      <div className="sbn-execution__paragraph sbn-execution__paragraph--2">
        <p className="main-copy">
          Each film profiled a
          <br />
          musician's connection to
          <br />
          music and how the Sonos
          <br />
          ecosystem has become an
          <br />
          integral part of their process
          <br />
          as well as their relationship to
          <br />
          each other.
          <br />
          <br />
          In one of the films, we
          <br />
          featured Dr. Lonnie-Smith
          <br />
          and Q-tip in a conversation
          <br />
          about the iconic hip hop track
          <br />
          "Can I kick it?" that sampled
          <br />
          Smith's Blue Note original.
        </p>
      </div>
      <div className="sbn-execution__img sbn-execution__img--1">
        <img src={img08} alt="title treatment" />
      </div>
      <VideoPlayer
        className="sbn-execution__vid sbn-execution__vid--1"
        poster={poster09}
        src={vid09}
      />
      <Parallax
        className="sbn-execution__img sbn-execution__img--2"
        y={[40, -40]}
      >
        <ParallaxImage src={img10} alt="speaker" />
      </Parallax>
      <div className="sbn-execution__img sbn-execution__img--3">
        <img src={img11} alt="face" />
      </div>
      <VideoPlayer
        className="sbn-execution__vid sbn-execution__vid--2"
        poster={poster12}
        src={vid12}
      />
    </div>
  )
}

function Conclusion({ images, videos }) {
  const [poster13, img14, img15, img16] = images;
  const [vid13] = videos;

  return (
    <div className="sbn-conclusion">
      <Parallax
        className="sbn-conclusion__blockquote"
        y={[40, -40]}
      >
        <blockquote className="blockquote">
          BASED OFF THE SUCCESS OF
          <br />
          THE SERIES, WE EXPANDED
          <br />
          OUR ROLE BY DEVELOPING
          <br />
          A SERIES OF PRODUCT
          <br />
          PROMOS ACROSS SOCIAL
          <br />
          & DIGITAL CHANNELS.
        </blockquote>
      </Parallax>
      <div className="sbn-conclusion__tagline">
        <p className="tagline">
          PURE SONOS
        </p>
      </div>
      <VideoPlayer
        className="sbn-conclusion__vid sbn-conclusion__vid--1"
        poster={poster13}
        src={vid13}
      />
      <div className="sbn-conclusion__img sbn-conclusion__img--1">
        <img src={img14} alt="title treatment" />
      </div>
      <Parallax
        className="sbn-conclusion__img sbn-conclusion__img--2"
        y={[80, -40]}
      >
        <ParallaxImage src={img15} alt="title treatment" />
      </Parallax>
      <div className="sbn-conclusion__img sbn-conclusion__img--3">
        <img src={img16} alt="title treatment" />
      </div>
    </div>
  )
}

export default React.memo(SonosBlueNotePage);